export default function getUniqueValues<T>(data: T[], prop: string): T[] {
    // Handle empty data
    if (!data) {
      return [];
    }
  
    // Handle single object
    if (!Array.isArray(data)) {
      data = [data];
    }
  
    const uniqueValues = new Set<T>();
  
    for (const item of data) {
      let currentValue: any = item; // Allow any type for nested objects
      const propParts = prop.split(".");
  
      for (const part of propParts) {
        if (!currentValue || typeof currentValue !== "object") {
          break; // Stop traversal if object or property not found
        }
        currentValue = currentValue[part];
      }
  
      if (currentValue !== undefined) {
        uniqueValues.add(currentValue as T); // Cast to ensure type safety
      }
    }
  
    return Array.from(uniqueValues);
  }
  